body {
  padding-top: 40px;
  font-family: Open Sans, sans-serif;
}

@media (width >= 768px) {
  body {
    padding-top: 56px;
  }
}

body.cart-sidebar-active {
  overflow: hidden;
}

@media (width >= 768px) {
  body.cart-sidebar-active {
    margin-right: 14px;
  }
}

body.cart-sidebar-active .top-bar {
  z-index: 2147483002;
}

.anchor {
  visibility: hidden;
  display: block;
  position: relative;
  top: -86px;
}

@media (width >= 768px) {
  .anchor {
    top: -105px;
  }
}

.join {
  letter-spacing: .1em;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background: #00c9ac;
  border-radius: 50px;
  outline: none;
  padding: 17px 7%;
  font: 500 22px / 1em Bebas Neue, sans-serif;
  text-decoration: none;
  transition: background-color .3s, color .3s, opacity .3s;
  display: inline-block;
  box-shadow: 0 0 #00000059;
}

@media (width >= 768px) {
  .join {
    font-size: 30px;
  }
}

.join:hover, .join:focus {
  color: #fff;
  background: #00fcd8;
  box-shadow: 0 0 7px #00000059;
}

.join.green {
  background: #10d05f;
}

.join.green:hover, .join.green:focus {
  background: #25ee78;
}

.join.musora-black {
  background: #000c17;
}

.join.musora-black:hover, .join.musora-black:focus {
  background: #00274a;
}

.join.sold-out {
  background: #777;
}

.join.sold-out:hover, .join.sold-out:focus {
  background: #919191;
}

.top-bar {
  z-index: 100;
  background: #020815;
  width: 100%;
  max-width: none;
  height: 40px;
  padding: 0;
  transition: background-color .3s, box-shadow .3s;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 10px #0003;
}

@media (width >= 768px) {
  .top-bar {
    height: 56px;
  }
}

@media screen and (width <= 767px) {
  .top-bar .show-for-medium {
    display: none !important;
  }
}

.top-bar .logo {
  box-sizing: border-box;
  float: left;
  width: 100%;
  max-width: 100px;
  height: 100%;
  padding: 7px 0 10px 10px;
  display: inline-block;
}

@media (width >= 768px) {
  .top-bar .logo {
    max-width: 157px;
    padding: 10px 16px;
  }
}

.top-bar .logo img {
  height: 100%;
  display: inline-block;
}

.top-bar .edge-wrap {
  color: #fff;
  box-sizing: border-box;
  float: left;
  height: 100%;
  padding: 6px 5px 6px 0;
  display: inline-block;
}

@media (width >= 768px) {
  .top-bar .edge-wrap {
    padding: 15px 0;
  }
}

@media (width >= 1024px) {
  .top-bar .edge-wrap {
    padding: 15px;
  }
}

.top-bar .edge-wrap a, .top-bar .edge-wrap span {
  letter-spacing: .1em;
  text-transform: uppercase;
  color: inherit;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  outline: none;
  width: auto;
  margin-left: 15px;
  padding: 4px 0;
  font: 500 13px / 1em Bebas Neue, sans-serif;
  text-decoration: none;
  transition: color .3s;
  display: inline-block;
}

@media (width >= 768px) {
  .top-bar .edge-wrap a, .top-bar .edge-wrap span {
    margin-left: 15px;
    font-size: 16px;
  }
}

@media (width >= 1024px) {
  .top-bar .edge-wrap a, .top-bar .edge-wrap span {
    margin-left: 21px;
  }
}

.top-bar .edge-wrap a:hover, .top-bar .edge-wrap span:hover {
  color: #30ffe1;
}

.top-bar .edge-wrap a:first-child, .top-bar .edge-wrap span:first-child {
  margin-left: 0;
}

.top-bar .edge-wrap a.active, .top-bar .edge-wrap a.active:hover, .top-bar .edge-wrap span.active, .top-bar .edge-wrap span.active:hover {
  color: #00c9ac;
}

@media (width <= 767px) {
  .top-bar .features-dd, .top-bar .instruments-dd {
    display: none !important;
  }
}

.top-bar .button-wrap {
  box-sizing: border-box;
  float: right;
  height: 100%;
  padding: 6px 5px 6px 0;
  display: inline-block;
}

@media (width >= 768px) {
  .top-bar .button-wrap {
    padding: 8px 0;
  }
}

.top-bar .button-wrap .join {
  width: auto;
  padding: 7px 14px 6px;
  font-size: 14px;
}

@media (width >= 768px) {
  .top-bar .button-wrap .join {
    padding: 13px 20px 12px;
    font-size: 15px;
  }
}

@media (width >= 1024px) {
  .top-bar .button-wrap .join {
    padding: 13px 30px 12px;
  }
}

.top-bar .button-wrap .join.outline-button {
  background: none;
  border: 2px solid #fff;
  margin-right: 5px;
  padding: 5px 12px 4px;
}

@media (width >= 768px) {
  .top-bar .button-wrap .join.outline-button {
    padding: 11px 30px 10px;
  }
}

.top-bar .button-wrap .join.outline-button:hover {
  color: #020815;
  background: #fff;
}

.top-bar .button-wrap .join.outline-button.promo {
  color: #d61922;
  border-color: #d61922;
}

.top-bar .button-wrap .join.outline-button.promo:hover {
  color: #020815;
  background: #d61922;
}

.top-bar .button-wrap .join.cart-button {
  position: relative;
}

.top-bar .button-wrap .join.cart-button .cart-number {
  color: #fff;
  background: red;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font: 700 10px / 15px Open Sans, sans-serif;
  position: absolute;
  top: 0;
  right: 0;
}

.top-bar .menu-toggle {
  box-sizing: border-box;
  float: right;
  color: #fff;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  width: 40px;
  height: 100%;
  padding: 10px 0;
  display: inline-block;
}

@media (width >= 768px) {
  .top-bar .menu-toggle {
    width: auto;
    padding: 18px 24px;
  }
}

.top-bar .menu-toggle span {
  background-color: #fff;
  border-radius: 25px;
  width: 16px;
  height: 3px;
  margin: 3px auto;
  transition: all .3s;
  display: block;
}

.top-bar .menu-toggle.active span:nth-child(2) {
  opacity: 0;
}

.top-bar .menu-toggle.active span:first-child {
  transform: translateY(6px)rotate(45deg);
}

.top-bar .menu-toggle.active span:nth-child(3) {
  transform: translateY(-6px)rotate(-45deg);
}

.nav-side-bar {
  z-index: 101;
  background: #fff;
  width: 90%;
  max-width: 400px;
  height: calc(100% - 40px);
  transition: all .1s;
  position: fixed;
  top: 40px;
  right: -100%;
  overflow: hidden auto;
}

@media (width >= 768px) {
  .nav-side-bar {
    height: calc(100% - 56px);
    top: 56px;
  }
}

.nav-side-bar.active {
  right: 0;
}

.nav-side-bar .has-drop-down {
  cursor: pointer;
}

.nav-side-bar .nav-link {
  color: #333;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  border-bottom: 1px solid #ededed;
  width: 100%;
  padding: 15px 20px;
  font: 400 18px / 1em Open Sans, sans-serif;
  display: inline-block;
  position: relative;
}

.nav-side-bar .nav-link:hover {
  background: #fafafa;
}

.nav-side-bar .nav-link i {
  color: #00c9ac;
  text-align: center;
  width: 25px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
  top: 3px;
}

.nav-side-bar .nav-link i.fa, .nav-side-bar .nav-link i.far, .nav-side-bar .nav-link i.fas, .nav-side-bar .nav-link i.fal, .nav-side-bar .nav-link i.fab {
  top: 0;
}

.nav-side-bar .nav-link i.fa-external-link {
  color: #000;
  font-size: 16px;
}

.nav-side-bar .nav-link .drop-down-arrow {
  float: right;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding: 7px;
}

.nav-side-bar .nav-link .drop-down-arrow span {
  background-color: #00c9ac;
  border-radius: 25px;
  width: 12px;
  height: 3px;
  transition: all .3s;
  display: block;
}

.nav-side-bar .nav-link .drop-down-arrow span:first-child {
  transform: translate(-4px, 2px)rotate(45deg);
}

.nav-side-bar .nav-link .drop-down-arrow span:nth-child(2) {
  transform: translate(4px, -1px)rotate(-45deg);
}

.nav-side-bar .nav-link .drop-down-arrow.active span:first-child {
  transform: translate(-4px, 2px)rotate(-45deg);
}

.nav-side-bar .nav-link .drop-down-arrow.active span:nth-child(2) {
  transform: translate(4px, -1px)rotate(45deg);
}

.nav-side-bar .lesson-links {
  max-height: 0;
  transition: all .1s;
  overflow: hidden;
  transform: translateZ(0);
}

.nav-side-bar .lesson-links.active {
  max-height: 1400px;
}

.nav-side-bar .lesson-links .nav-link {
  background: #ededed;
  font-size: 17px;
}

.nav-side-bar .lesson-links .nav-link:hover {
  background: #f0f0f0;
}

.nav-side-bar .lesson-links .nav-link i {
  color: #000;
  font-size: 17px;
}

.nav-side-bar .secondary-link {
  color: #000;
  padding: 10px 20px;
  font: 400 16px / 1em Open Sans, sans-serif;
}

.nav-side-bar .secondary-link:hover {
  text-decoration: underline;
}

.nav-side-bar .shim {
  height: 15px;
  display: block;
}

.menu-overlay {
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  background: #0003;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: fixed;
  top: 0;
}

.menu-overlay.active {
  visibility: visible;
  opacity: 1;
}

.cookie-notice {
  color: #000;
  z-index: 2147483002;
  background: #fff;
  border-radius: 12px;
  width: 100%;
  max-width: 380px;
  padding: 10px 10px 10px 15px;
  transition: all .3s;
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 0 10px #00000080;
}

.cookie-notice.hide {
  bottom: -275px;
}

@media (width >= 768px) {
  .cookie-notice.hide {
    bottom: -175px;
  }
}

.cookie-notice .text-wrap {
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.cookie-notice p {
  margin: 0 auto;
  font-size: 15px;
  line-height: 1.2em;
}

@media (width >= 1024px) {
  .cookie-notice p {
    line-height: 1.5em;
  }
}

.cookie-notice .text-center {
  text-align: center;
}

.cookie-notice a {
  color: #00c9ac;
  white-space: nowrap;
  cursor: pointer;
}

.cookie-notice a:hover {
  text-decoration: underline;
}

.cookie-notice #accept-cookies {
  white-space: nowrap;
  cursor: pointer;
  letter-spacing: .1em;
  color: #fff;
  background: #00c9ac;
  border-radius: 200px;
  margin: 10px auto 4px;
  padding: 10px 25px;
  font: 400 16px / 1em Bebas Neue, sans-serif;
  transition: background .3s;
}

@media (width >= 768px) {
  .cookie-notice #accept-cookies {
    margin: 0 auto 2px;
    padding: 15px 20px;
  }
}

@media (width >= 1024px) {
  .cookie-notice #accept-cookies {
    margin: 0 auto;
    padding: 15px 25px;
  }
}

.cookie-notice #accept-cookies:hover {
  background: #00fcd8;
}

.bottom-footer {
  text-align: center;
  background: #111729;
  padding: 35px 0 25px;
}

@media (width >= 768px) {
  .bottom-footer {
    padding: 50px 0 35px;
  }
}

.bottom-footer .container {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}

.bottom-footer .container:before, .bottom-footer .container:after {
  content: " ";
  display: table;
}

.bottom-footer .container:after {
  clear: both;
}

.bottom-footer .footer-link-wrap {
  vertical-align: top;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 15px;
  display: inline-block;
}

@media (width >= 768px) {
  .bottom-footer .footer-link-wrap {
    text-align: left;
    width: auto;
    margin-bottom: 30px;
    padding: 0 7px;
  }
}

@media (width >= 1024px) {
  .bottom-footer .footer-link-wrap {
    padding: 0 40px;
  }
}

@media (width >= 768px) {
  .bottom-footer .footer-link-wrap.footer-sign-up {
    width: 30%;
    margin: 0 auto;
  }
}

@media (width >= 1024px) {
  .bottom-footer .footer-link-wrap.footer-sign-up {
    width: 340px;
  }

  .bottom-footer .footer-link-wrap.footer-sign-up .hide-for-desktop {
    display: none;
  }
}

.bottom-footer .footer-link-wrap.footer-sign-up .show-for-desktop {
  display: none;
}

@media (width >= 1024px) {
  .bottom-footer .footer-link-wrap.footer-sign-up .show-for-desktop {
    display: inline;
  }
}

.bottom-footer .footer-link-wrap.footer-sign-up .col-xs-12 {
  float: left;
  width: 100%;
  padding: 0;
}

@media (width >= 1024px) {
  .bottom-footer .footer-link-wrap.footer-sign-up .col-xs-12 {
    padding: 0 5px 0 0;
  }

  .bottom-footer .footer-link-wrap.footer-sign-up .col-xs-12.col-sm-7 {
    width: 58.33%;
  }

  .bottom-footer .footer-link-wrap.footer-sign-up .col-xs-12.col-sm-5 {
    width: 41.66%;
  }
}

.bottom-footer .footer-link-wrap.footer-sign-up form {
  width: 100%;
  max-width: 220px;
  margin: 10px auto 0;
  position: relative;
}

@media (width >= 768px) {
  .bottom-footer .footer-link-wrap.footer-sign-up form {
    max-width: 100%;
  }
}

.bottom-footer .footer-link-wrap.footer-sign-up form input, .bottom-footer .footer-link-wrap.footer-sign-up form button {
  color: #fff;
  text-align: left;
  background: #49535a;
  border: none;
  border-radius: 100px;
  outline: none;
  width: 100%;
  height: 35px;
  margin: 0 auto 7px;
  padding: 7px 20px;
  font: 400 14px / 35px Open Sans, sans-serif;
}

@media (width >= 1024px) {
  .bottom-footer .footer-link-wrap.footer-sign-up form input, .bottom-footer .footer-link-wrap.footer-sign-up form button {
    margin: 0 auto;
  }
}

.bottom-footer .footer-link-wrap.footer-sign-up form input[type="submit"], .bottom-footer .footer-link-wrap.footer-sign-up form input button, .bottom-footer .footer-link-wrap.footer-sign-up form button[type="submit"], .bottom-footer .footer-link-wrap.footer-sign-up form button button {
  letter-spacing: .1em;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  color: #00c9ac;
  background: none;
  border: 2px solid #00c9ac;
  margin: 0;
  padding: 0;
  font-family: Bebas Neue, sans-serif;
  line-height: 31px;
  display: inline-block;
}

.bottom-footer .footer-link-wrap.footer-sign-up form input[type="submit"]:hover, .bottom-footer .footer-link-wrap.footer-sign-up form input button:hover, .bottom-footer .footer-link-wrap.footer-sign-up form button[type="submit"]:hover, .bottom-footer .footer-link-wrap.footer-sign-up form button button:hover {
  color: #000;
  background: #00c9ac;
}

.bottom-footer .footer-link-wrap.footer-sign-up .thank-you-box {
  visibility: hidden;
  opacity: 0;
  text-align: center;
  background: none;
  border-radius: 5px;
  width: 100%;
  max-width: 960px;
  height: auto;
  max-height: 0;
  margin: 0 auto;
  transition: all .4s ease-in;
  display: block;
  overflow: hidden;
}

@media (width >= 768px) {
  .bottom-footer .footer-link-wrap.footer-sign-up .thank-you-box {
    text-align: left;
  }
}

.bottom-footer .footer-link-wrap.footer-sign-up .thank-you-box.active {
  visibility: visible;
  opacity: 1;
  max-height: 1000px;
  padding: 5px 0 0;
}

.bottom-footer .footer-link-wrap.footer-sign-up .thank-you-box p {
  color: #fff;
  margin: 0 auto;
  font: 400 13px / 1.55em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .bottom-footer .footer-link-wrap.footer-sign-up .thank-you-box p {
    font-size: 15px;
  }
}

.bottom-footer h1 {
  color: #fff;
  text-transform: uppercase;
  margin: 0 auto;
  font: 400 24px / 2em Bebas Neue, sans-serif;
}

@media (width >= 768px) {
  .bottom-footer h1 {
    font-size: 22px;
  }
}

.bottom-footer h1 a {
  color: inherit;
  display: inline-block;
}

.bottom-footer h1 a:hover {
  color: #dfdfdf;
}

.bottom-footer p {
  color: #879097;
  margin: 0 auto;
  font: 400 17px / 2em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .bottom-footer p {
    font-size: 16px;
  }
}

.bottom-footer p a {
  font-size: inherit;
  color: inherit;
  display: inline-block;
}

.bottom-footer p a:hover {
  color: #a2a9af;
}

.bottom-footer p.tiny {
  font-size: 12px;
}

.bottom-footer .logo {
  opacity: .55;
  width: 120px;
  margin: 0 auto 10px;
}

@media (width >= 768px) {
  .bottom-footer .logo {
    width: 140px;
    margin: 0 auto 15px;
  }
}

.bottom-footer .social-media {
  color: #5c6870;
  text-align: center;
  border: 2px solid #5c6870;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 20px 1px 15px;
  font-size: 20px;
  line-height: 36px;
  transition: all .3s;
  display: inline-block;
  transform: scale(1.01);
  box-shadow: 0 0 #000;
}

.bottom-footer .social-media:hover {
  color: #fff;
  transform: scale(1.2);
  box-shadow: 0 0 15px #000;
}

.bottom-footer .social-media:hover.youtube {
  background: #cd201f;
  border-color: #cd201f;
}

.bottom-footer .social-media:hover.facebook {
  background: #3b5998;
  border-color: #3b5998;
}

.bottom-footer .social-media:hover.instagram {
  background: linear-gradient(30deg, #ffd521 17%, #f20008 50%, #b900b4 83%);
  border-color: #b900b4;
}

.bottom-footer .social-media:hover.tiktok {
  background: #000;
  border-color: #25f4ee;
}

.bottom-footer .social-media:hover.podcast {
  background: linear-gradient(#d36cfb, #8630c2);
  border-color: #a945e3;
}

@media (width >= 768px) {
  .bottom-footer br.mobile-only {
    display: none;
  }
}

.bottom-footer .disclaimer {
  display: none;
}

.bottom-footer .footer-bottom {
  text-align: center;
  border-top: 1px solid #242729;
  padding: 20px 0 0;
}

@media (width >= 768px) {
  .bottom-footer .footer-bottom {
    padding: 25px 0 0;
  }
}

.vue-add-to-cart .loading {
  display: none;
}

.vue-add-to-cart .initial, .vue-add-to-cart.loading .loading {
  display: inline-block;
}

.vue-add-to-cart.loading .initial {
  display: none;
}

.BeaconFabButtonFrame {
  bottom: 5px !important;
  right: 3px !important;
}
/*# sourceMappingURL=nav-footer-guitareo.css.map */
